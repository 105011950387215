import styled from "styled-components";
import { ReactComponent as TwitterIcon } from "assets/icons/Twitter.svg";
import { ReactComponent as LinkendinIcon } from "assets/icons/Linkedin.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/Instagram.svg";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 90px 0 80px;
  box-sizing: border-box;

  a {
    text-decoration: none;
  }
`;

const FooterText = styled.p`
  font-family: "Neue-Haas";
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 11px;
  line-height: 23px;
  text-align: center;
  padding: 40px 100px 64px;
  max-width: 800px;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 40px 40px 64px;
  }
`;

const SocialIcons = styled.ul`
  display: flex;
  svg {
    margin-right: 40px;
  }
`;

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <FooterContainer className="container">
      <SocialIcons>
        <a href="https://twitter.com/776foundation" target="_blank" rel="noreferrer">
          <TwitterIcon />
        </a>
        <a href="https://www.linkedin.com/company/776foundation" target="_blank" rel="noreferrer">
          <LinkendinIcon />
        </a>
        <a href="https://www.instagram.com/776foundation" target="_blank" rel="noreferrer">
          <InstagramIcon />
        </a>
      </SocialIcons>
      <FooterText>
        SEVEN SEVEN SIX © {year} 776 Fund Management, 776 logo, Heptagon/Heptagon/Hexagon Logo, and “Seven Seven Six”
        are all trademarks of 776 Fund Management. All Rights Reserved.
      </FooterText>
      <a href="https://sevensevensix.com" target="_blank" rel="noreferrer">
        💼
      </a>
    </FooterContainer>
  );
};

export default Footer;
