import styled from "styled-components";

const GoalSectionWrapper = styled.div`
  padding: 30px 0 80px 0;
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 70px;

  h5 {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    line-height: 64px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 0 50px;
    margin-bottom: 15px;

    h5 {
      line-height: 33px;
      font-size: 20px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1018px;

  .content-data {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      &:first-child {
        h6 {
          max-width: 350px;
        }
      }

      &:nth-child(2) {
        margin-top: -90px;
        margin-left: auto;
        padding-right: 10px;
      }

      &:last-child {
        margin-top: -40px;
      }
    }

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      padding: 0 40px 0 20px;
      max-width: 100%;
      justify-content: center;
      align-items: center;

      div:nth-child(2) {
        margin-top: 0;
        margin-left: 1px;
      }

      div:last-child {
        margin-top: 0;
      }
    }
  }
`;

const ContentItem = styled.div`
  max-width: 450px;

  p {
    color: #cecfd0;
  }

  p {
    font-family: "Neue-Haas";
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
  }

  h6 {
    max-width: 305px;
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 18px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    p {
      font-size: 14px;
      line-height: 20px;
    }

    .mb-sm-sc {
      margin-bottom: 0;
    }

    h6 {
      max-width: 280px;
      font-size: 18px;
      line-height: 25px;
      padding-right: 30px;
    }
  }
`;

const Goals: React.FC = () => {
  return (
    <GoalSectionWrapper>
      <Title>
        <h5>Goals of the Fellowship Program</h5>
      </Title>
      <Content>
        <div className="content-data">
          <ContentItem>
            <h6>🌎 Invest in young people tackling climate change</h6>
            <p className="mb-sm-sc">
              Some of our best minds around the world aren’t able to take two years off to focus on solving the greatest
              threat to humanity: climate change.
            </p>
            <p>
              We’re equipping some of the most driven future leaders with the capital and support needed to fully
              immerse themselves in what could be the next world-saving idea.
            </p>
          </ContentItem>
          <ContentItem>
            <h6>✨ Scale the impact of 776</h6>
            <p>
              The team at 776 is tasked with backing exceptional founders as early as possible in their journey, giving
              potential founders the space and time (and money) to build something great.
            </p>
          </ContentItem>
          <ContentItem>
            <h6>💪 Enrich our community with the next generation of talent</h6>
            <p>
              This program will deeply challenge our Fellows to think critically and creatively and will turnout the
              next generation of great founders and operators. This Fellowship program can be the springboard for
              unlimited opportunities because it’s reaching so deep into a pool of talented individuals before their
              careers have even started.
            </p>
          </ContentItem>
        </div>
      </Content>
    </GoalSectionWrapper>
  );
};

export default Goals;
