import ReCAPTCHA from "react-google-recaptcha";
import { API_URL, RECAPTCHA_API_KEY } from "config/Config";
import styled from "styled-components";
import React, { FC, useState } from "react";
import { processServerResponse } from "utils/httpUtils";
import { validateEmail } from "utils/utils";
import Input from "./form/Input";

interface Form {
  firstName: string;
  lastName: string;
  twitter: string;
  email: string;
}

const SuccessDiv = styled.div`
  h3 {
    font-weight: 700;
    font-size: 21px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
  }
`;
const FormSection = styled.form`
  .grecaptcha-badge {
    display: none !important;
  }
  .flex-inputs {
    display: flex;
    justify-content: space-between;

    .mr-15 {
      margin-right: 15px;
    }
  }

  .form-input,
  .form-input.outline {
    &:focus {
      border-color: #00a0d8;
      transition: all ease-in-out 0.4s;
    }
  }
  .main-button {
    display: block;
    margin-left: auto;
    font-size: 17px;

    &:disabled {
      background-color: #686868;
    }
  }
`;

const MailingListForm: FC<{
  onSubmit: () => void;
}> = ({ onSubmit }) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [form, setForm] = useState<Form>({
    firstName: "",
    lastName: "",
    twitter: "",
    email: "",
  });
  const [errors, setErrors] = useState<Partial<Form>>({});

  const recaptchaRef = React.createRef();

  const validateForm = () => {
    const newErrors: Partial<Form> = {};
    if (!form.firstName.trim()) {
      newErrors.firstName = "First Name missing";
    }
    if (!form.lastName.trim()) {
      newErrors.lastName = "Last Name missing";
    }
    if (!form.email.trim()) {
      newErrors.email = "Email missing";
    } else if (!validateEmail(form.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    setErrors(newErrors);

    return !Object.keys(newErrors).length;
  };

  const apply = async () => {
    setIsDirty(true);
    if (!validateForm()) {
      return;
    }

    setSaving(true);
    try {
      //@ts-ignore
      const token = await recaptchaRef.current.executeAsync();
      if (!token) return;

      const payload = { ...form };

      if (!payload.twitter.trim()) {
        delete payload.twitter;
      }

      const response = await fetch(`${API_URL}/fellowship/mailing-list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      await processServerResponse(response);
      setSaving(false);
      onSubmit?.();
      setIsSubmitted(true);
      clear();
    } catch (e) {
      if (e.httpStatus === "409") {
        setErrors({
          email: "You're already on our list!",
        });
      }
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const updateFormProperty = (key, value) => {
    setForm((currentForm) => ({
      ...currentForm,
      [key]: value,
    }));

    if (isDirty) {
      validateForm();
    }
  };

  const clear = () => {
    setForm({
      firstName: "",
      lastName: "",
      twitter: "",
      email: "",
    });
    setErrors({});
    setIsDirty(false);
    setSaving(false);
  };

  if (isSubmitted) {
    return (
      <SuccessDiv className="submit-success-screen">
        <h3>thank you for submitting</h3>
      </SuccessDiv>
    );
  }

  return (
    <FormSection>
      <div className="flex-inputs">
        <div className="mr-15">
          <Input
            label="First Name"
            type="text"
            variant="outline"
            errorColor="#fff"
            maxLength={100}
            value={form.firstName}
            error={errors.firstName}
            onChange={(e) => updateFormProperty("firstName", e.target.value)}
          />
        </div>
        <Input
          label="Last Name"
          type="text"
          variant="outline"
          errorColor="#fff"
          maxLength={100}
          value={form.lastName}
          error={errors.lastName}
          onChange={(e) => updateFormProperty("lastName", e.target.value)}
        />
      </div>
      <Input
        label="Twitter (optional)"
        type="text"
        prefix="@"
        variant="outline"
        errorColor="#fff"
        maxLength={100}
        value={form.twitter}
        error={errors.twitter}
        onChange={(e) => updateFormProperty("twitter", e.target.value)}
      />
      <Input
        label="Email"
        variant="outline"
        errorColor="#fff"
        maxLength={100}
        value={form.email}
        error={errors.email}
        onChange={(e) => updateFormProperty("email", e.target.value)}
      />
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_API_KEY} />
      <button type="button" disabled={saving} className="main-button medium" onClick={apply}>
        {saving ? "Submitting..." : "Submit"}
      </button>
    </FormSection>
  );
};

export default MailingListForm;
