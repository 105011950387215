import { API_URL } from "config/Config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { processServerResponse } from "utils/httpUtils";

export interface Fellow {
  firstName: string;
  lastName: string;
  organizationName: string;
  picture: string;
  bio: string;
  cohort: string;
  position: string;
  displayPosition: string;
  instagram: string;
  linkedin: string;
  angelList: string;
  tiktok: string;
  twitter: string;
  web: string;
}

const useFellows = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [fellows, setFellows] = useState<Fellow[]>([]);

  const loadFellows = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/public/landing-page/776-org/fellows`, {
        method: "GET",
      });
      const data = await processServerResponse(response);
      setFellows(data as Fellow[]);
    } catch (error) {
      console.error("Error fetching fellows", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadFellows();
  }, [loadFellows]);

  return useMemo(() => ({ fellows, loading }), [fellows, loading]);
};

export default useFellows;
