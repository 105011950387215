import styled from "styled-components";

const FieldErrorWrapper = styled.div`
  font-size: 11px;
  line-height: 20px;
  margin-top: 5px;
`;

const FieldError: React.FC<{
  text?: string;
  color?: string;
}> = ({ text, color }) => {
  return <FieldErrorWrapper style={{ color }}>{text}</FieldErrorWrapper>;
};

FieldError.defaultProps = {
  color: "#d40000",
};

export default FieldError;
