import styled from "styled-components";
import { Footer, Header } from "../components";
import Background from "assets/images/bg-desktop.jpg";
import MobileBackground from "assets/images/bg-mobile.jpg";
import FellowsList from "components/fellows/FellowsList";
import { useEffect } from "react";

const FellowsDiv = styled.div`
  min-height: 100vh;
`;
const Wrapper = styled.div<{ background: { mobile: string; desktop: string } }>``;

const Fellows: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FellowsDiv>
      <Header />
      <Wrapper className="w-full" background={{ mobile: MobileBackground, desktop: Background }}>
        <FellowsList />
      </Wrapper>
      <Footer />
    </FellowsDiv>
  );
};

export default Fellows;
