import styled from "styled-components";

const ProgramMissionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0 15px 0;
  text-align: left;

  h5 {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    line-height: 64px;
  }

  p {
    font-family: "Neue-Haas";
    font-size: 16px;
    color: #959595;
    max-width: 600px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 0 40px 0 20px;
    margin-bottom: 15px;
    align-items: flex-start;

    h5 {
      line-height: 33px;
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }
`;

const ProgramMission: React.FC = () => {
  return (
    <ProgramMissionWrapper>
      <h5> Program Mission & Vision</h5>
      <p>
        There are some big ideas that can’t wait—especially when it comes to climate change—and 776 wants to support
        problem-solvers at the earliest possible stage. The Fellowship Program gives 20 remarkable people the age of 24
        and under the chance to spend two years working on the bright idea that’s keeping them up at night. They’ll
        benefit from a community of peers and access to the extended 776 network through Cerebro (our firm’s operating
        system).
      </p>
    </ProgramMissionWrapper>
  );
};

export default ProgramMission;
