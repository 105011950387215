import styled from "styled-components";
import { FC, useEffect, useState } from "react";
import { ReactComponent as AngelListIcon } from "../../assets/socials/angellist.svg";
import { ReactComponent as InstagramIcon } from "../../assets/socials/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/socials/linkedin.svg";
import { ReactComponent as TikTokIcon } from "../../assets/socials/tiktok.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/socials/website.svg";
import { ReactComponent as XIcon } from "../../assets/socials/x.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-icon.svg";
import { Fellow } from "components/fellows/useFellows";

const SocialIcons = {
  twitter: XIcon,
  linkedin: LinkedInIcon,
  instagram: InstagramIcon,
  tiktok: TikTokIcon,
  angelList: AngelListIcon,
  web: WebsiteIcon,
};

const FellowPreviewWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 99;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
  }

  .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    transition: all ease 0.3s;
    z-index: 11;
    &:hover {
      transform: scale(1.1);
    }
  }

  .info {
    flex: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.hoverDark};
    color: ${({ theme }) => theme.colors.inputBorder};

    & > div {
      padding: 60px 81px;
      max-width: 50rem;
      display: flex;
      flex-direction: column;
      margin: auto;
    }

    h2 {
      font-size: 65px;
      font-weight: 600;
      line-height: 1;
      word-break: break-word;
      word-spacing: 50000px;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.white};
    }

    span {
      display: block;
      color: ${({ theme }) => theme.colors.textPrimary};
      font-size: 21px;
      font-weight: 600;
      line-height: 1.5;
    }

    p {
      margin-top: 60px;
      color: ${({ theme }) => theme.colors.textPrimary};
      font-size: 17px;
      font-weight: 400;
      line-height: 1.65;
      cursor: auto;

      padding-bottom: 40px;
    }
  }

  .image-wrapper {
    display: block;
    height: 100%;
    flex: 1 1 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 237px;
      max-height: 280px;
      object-fit: contain;
      z-index: 9;
    }

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      display: none;
    }
  }

  .fellow-socials {
    display: flex;
    flex-direction: row nowrap;
    gap: 15px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 75px;
  height: 75px;
  circle {
    fill: ${({ theme }) => theme.colors.borderDark};
  }
  path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledSocialLink = styled("a")`
  svg {
    circle {
      stroke: ${({ theme }) => theme.colors.white};
    }

    path[stroke] {
      stroke: ${({ theme }) => theme.colors.white};
    }
    path[fill] {
      fill: ${({ theme }) => theme.colors.white};
    }

    &:hover {
      path[stroke] {
        transition: stroke 0.3s ease-in-out;
        stroke: ${({ theme }) => theme.colors.background};
      }
      path[fill] {
        transition: fill 0.3s ease-in-out;
        fill: ${({ theme }) => theme.colors.background};
      }
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

interface FellowPreviewProps {
  onClose: () => void;
  fellow: Fellow;
}

const FellowPreview: FC<FellowPreviewProps> = ({ fellow, onClose }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);

    document.body.style.touchAction = "none";
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.touchAction = "auto";
      document.body.style.overflowY = "auto";
    };
  }, []);

  if (!fellow) return null;

  const socials = ["instagram", "linkedin", "angelList", "tiktok", "twitter", "web"].filter((key) => !!fellow[key]);

  const fullName = `${fellow.firstName} ${fellow.lastName}`;

  return (
    <FellowPreviewWrapper className={loaded ? "active" : ""}>
      <div className="info">
        <div>
          <h2>{fullName}</h2>
          <span>
            {fellow.organizationName || ""} ({fellow.cohort})
          </span>
          <p>{fellow.bio}</p>

          {!!socials && (
            <div className="fellow-socials">
              {socials.map((network) => {
                const SocialIcon = SocialIcons[network];
                return (
                  <StyledSocialLink key={network} href={socials[network]} target="_blank" rel="noopener noreferrer">
                    <SocialIcon />
                  </StyledSocialLink>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="image-wrapper">
        <img src={fellow.picture} alt={fullName} />
      </div>
      <button className="close-button" onClick={onClose}>
        <StyledCloseIcon />
      </button>
    </FellowPreviewWrapper>
  );
};

export default FellowPreview;
