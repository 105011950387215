import styled from "styled-components";
import { useMemo } from "react";
import { Fellow } from "components/fellows/useFellows";

const FellowCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 240px;
  transition: all 0.3s ease-in-out;
  img {
    width: 238px;
    height: 280px;
    object-fit: contain;
    margin: 0 auto;

    @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
      width: 135px;
    }
  }

  .multiline-ellipsis {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  h6 {
    font-size: 19px;
    font-weight: 600;
    margin: 30px 0 5px 0;
    color: #fff;
  }

  span {
    display: block;
    font-family: Neue-Haas, sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;
    color: #cecfd0;
  }

  p {
    font-family: Neue-Haas, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #cecfd0;
  }

  &.fellow:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    h6 {
      font-size: 20px;
      line-height: 33px;
      margin-bottom: 4px;
    }
    span {
      size: 14px;
    }
  }
`;

interface FellowCardProps {
  fellow: Fellow;
  onClick?: () => void;
}

const FellowCard: React.FC<FellowCardProps> = ({ fellow, onClick }) => {
  const description = useMemo(() => {
    return `${fellow.organizationName || ""} (${fellow.cohort})`;
  }, [fellow]);

  const fullName = `${fellow.firstName} ${fellow.lastName}`;

  return (
    <FellowCardWrapper onClick={onClick} className="fellow">
      <img src={fellow.picture} alt={fullName} />
      <h6>{fullName}</h6>
      <span>{description}</span>
    </FellowCardWrapper>
  );
};

export default FellowCard;
