import styled from "styled-components";
import useFellows from "./useFellows";
import FellowPreview from "components/team/FellowPreview";
import { useEffect, useState } from "react";
import FellowCard from "components/team/FellowCard";

const FellowsListPage = styled.section`
  width: 100%;
  padding-top: 190px;
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 50px;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px 92px;
    margin-top: 50px;
  }
`;

const FellowsList: React.FC = () => {
  const { fellows } = useFellows();

  const [previewId, setPreviewId] = useState<number | null>(null);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setPreviewId(null);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <FellowsListPage id="main" className="main-width">
      <div className="list">
        {fellows?.map((fellow, index) => (
          <FellowCard key={index} fellow={fellow} onClick={() => setPreviewId(index)} />
        ))}
      </div>

      {previewId !== null && <FellowPreview fellow={fellows[previewId]} onClose={() => setPreviewId(null)} />}
    </FellowsListPage>
  );
};

export default FellowsList;
