import styled from "styled-components";

const FormItemWrapper = styled.div`
  display: flex;
  align-items: center;
  .radio {
    margin-right: 10px;
    width: 17px;
    height: 17px;
    &:checked + label {
      font-weight: bold;
    }
  }
  label {
    font-family: Neue-Haas, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
  }
`;

const Radio: React.FC<{
  className?: string;
  checked: boolean;
  label: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, checked, name, value, onChange, className = "" }) => {
  return (
    <FormItemWrapper className={className}>
      <input type="radio" name={name} value={value} className="radio" onChange={onChange} checked={checked} />
      <label>{label}</label>
    </FormItemWrapper>
  );
};

export default Radio;
