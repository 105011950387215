import Lissie from "assets/images/Lissie-Garvin.png";
import Alexis from "assets/images/Alexis-Ohanian.png";

export interface IMember {
  name: string;
  cohort?: string;
  organizationName?: string;
  position: string;
  bio?: string;
  socials?: {
    twitter?: string;
    instagram?: string;
    linkedin?: string;
    tiktok?: string;
    angellist?: string;
    web?: string;
  };
  img: { src: string; alt: string };
}

const members: IMember[] = [
  {
    name: "Lissie Garvin",
    position: "776 Foundation and Fellowship Program Director",
    img: {
      src: Lissie,
      alt: "team-member",
    },
  },
  {
    name: "Alexis Ohanian",
    position: "President & Founder",
    img: {
      src: Alexis,
      alt: "team-member",
    }
  },
];

export default members;
