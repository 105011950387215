import styled from "styled-components";
import Label from "./Label";
import FieldError from "./FieldError";

const FormItemWrapper = styled.div`
  margin-bottom: 14px;
  .info-text {
    color: #959595;
    font-size: 11px;
    font-family: Neue-Haas, sans-serif;
    margin-top: 5px;
  }
`;

const FormItem: React.FC<{
  label?: string;
  error?: string;
  variant?: "default" | "outline";
  infoText?: string;
  errorColor?: string;
}> = ({ children, label, variant, infoText, errorColor, error }) => {
  if (!label) return null;

  return (
    <FormItemWrapper>
      <Label variant={variant} label={label} />
      {children}
      {error && <FieldError text={error} color={errorColor} />}
      {infoText && <div className="info-text">{infoText}</div>}
    </FormItemWrapper>
  );
};

export default FormItem;
