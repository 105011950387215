import clsx from "clsx";
import { FC, useState } from "react";
import styled from "styled-components";
import MailingListForm from "./MailingListForm";

const Section = styled.section`
  width: 100%;
  max-width: 885px;
  margin: 0 auto;
  padding: 75px 0 55px 0;
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 46px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 65px;
  }

  .form-wrapper {
    width: 100%;
    max-width: 345px;
    margin: 0 auto;

    &.success {
      max-width: 435px;
    }

    .submit-success-screen h3 {
      font-size: 30px;
    }
  }
`;

const MailingListSection: FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  return (
    <Section>
      {!isSubmitted && <h2>Submit your email we'll notify you when applications open for our 2025 Cohort!</h2>}
      <div className={clsx("form-wrapper", isSubmitted && "success")}>
        <MailingListForm onSubmit={() => setIsSubmitted(true)} />
      </div>
    </Section>
  );
};

export default MailingListSection;
