import styled from "styled-components";
import { ReactComponent as HexagonIcon } from "assets/icons/hexagon.svg";
import { TYPEFORM_LINK } from "config/Config";

const ApplicationSectionWrapper = styled.div`
  min-height: 522px;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  div {
    z-index: 1;

    h5 {
      font-weight: 600;
      font-size: 30px;
      line-height: 42px;
      margin: 15px 0 25px 0;
    }

    span {
      font-size: 30px;
    }

    p {
      color: #cecfd0;
      font-family: Neue-haas;
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 26px;
    }

    a {
      padding: 13px 50px;
      display: block;
      text-decoration: none;
      display: block;
      width: 200px;
      margin: 0 auto;
    }

    @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
      h5 {
        font-size: 20px;
        line-height: 33px;
      }

      a,
      p {
        font-size: 14px;
        font-size: 14px;
      }
    }
  }

  svg {
    position: absolute;

    @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
      width: 350px;
      height: 350px;
    }
  }
`;

const Application: React.FC = () => {
  return (
    <ApplicationSectionWrapper>
      <div id="application">
        <span>✨</span>
        <h5>
          LET'S BUILD
          <br />A BETTER FUTURE
        </h5>
        <a href={TYPEFORM_LINK} target="_blank" rel="noreferrer" className="main-button">
          Apply Now
        </a>
      </div>
      <HexagonIcon />
    </ApplicationSectionWrapper>
  );
};

export default Application;
